<template>
  <v-container style="padding-left: 20px">
    <app-header>
      <v-btn text @click="$router.back()">
        <v-icon>{{ 'mdi-arrow-left' }}</v-icon>
        {{ $t('back') }}
      </v-btn>

      <v-btn text @click="uploadFile">
        <v-icon color="green" style="padding-right: 5px">{{ 'mdi-check' }}</v-icon>
        {{ $t('save') }}
      </v-btn>

      <DownloadMenu :item="imageData"></DownloadMenu>

      <download-zip-menu :img-id="img" :item="imageData" :project-id="id"></download-zip-menu>
    </app-header>

    <v-overlay
        z-index="99"
        :value="isShown"
        @click.native="isShown=false"
    >
      <v-row style="padding-top: 250px" justify="center">
        <v-col cols="auto">
          <v-card>
            <v-card-text>
              {{ description }}
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn @click="isShown = false">
                {{ $t('close') }}
              </v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-overlay>

    <v-layout row :key="img || getFile.name">
      <v-col>
        <v-card tile :loading="loading">
          <v-container v-if="!loading" fluid>
            <v-row>
              <v-card
                  elevation="0">
                <v-card-title>
                  {{ imageData.info ? imageData.info.fileName : $t('upload.title') }}
                </v-card-title>
                <v-card-subtitle>
                  {{ `ID: ${img}` }}
                </v-card-subtitle>
              </v-card>
              <v-spacer>
              </v-spacer>
              <v-card-actions>
                <PreviewOverlay :source="url || null"
                                :src="imageData.galleryUrl ? webImages + imageData.galleryUrl + '?rnd=' + Math.random() : ''"
                                v-if="imageData.info.type === 'IMAGE' || imageData.info.type === 'PDF'"
                                :filename="(imageData ? imageData.info.fileName : '')"
                                :img="img"
                                :size="false"
                >
                  {{ $t('upload.preview') }}
                  <v-icon color="blue"
                          style="padding-left: 5px">
                    {{ 'mdi-eye-outline' }}
                  </v-icon>
                </PreviewOverlay>

                <v-btn text @click="onMarked(imageData);">
                  {{ $t('upload.markFile') }}
                  <v-icon color="yellow" style="padding-left: 5px">
                    {{
                      imageData.info ? (imageData.info.marked ? 'mdi-star' : 'mdi-star-outline') : 'mdi-star-outline'
                    }}
                  </v-icon>
                </v-btn>
              </v-card-actions>
            </v-row>
            <v-row>
              <h1 class="pl-6 heading" v-if="basic.length"> {{ $t('basic') }}
              </h1>
              <v-col md="12" v-for="section in basic" v-if="section.enabled" :key="section.indexName">
                <template v-if="section.type === 'TEXT'">
                  <v-text-field v-if="section.formType === 'field'" v-model="section.value"
                                :label="section.label[getLocale] || section.label['en-EN']"
                                prepend-icon="mdi-information"
                                @click:prepend="showDescription(section, section.indexName.replaceAll('.','_'));"
                                :ref="section.indexName.replaceAll('.','_')"
                                :id="section.indexName.replaceAll('.','_')">
                  </v-text-field>

                  <v-select
                      v-if="section.formType === 'dropdown' && !section.priority"
                      prepend-icon="mdi-information"
                      @click:prepend="showDescription(section, section.indexName.replaceAll('.','_'))"
                      :items="section.items"
                      :label="section.label[getLocale]  || section.label['en-EN']"
                      v-model="section.value"
                      :ref="section.indexName.replaceAll('.','_')"
                      :id="section.indexName.replaceAll('.','_')"
                  >
                  </v-select>

                  <template v-if="section.formType === 'rating' && !section.priority && !isNaN(section.value)">
                    <h1>{{ section.label[getLocale] || section.label['en-EN'] }}</h1>
                    <v-rating color="warning" background-color="grey"
                              hover
                              :value="parseInt(section.value)"
                              @input="(x) => {section.value = x + '.0'}"
                              :ref="section.indexName.replaceAll('.','_')">

                    </v-rating>
                  </template>
                  <v-combobox
                      v-model="section.value"
                      :search-input.sync="section.value"
                      :items="distinctItems['metadata.' + section.indexName]"
                      v-if="section.formType === 'selection' && (!section.items || !section.items.length)"
                      :label="section.label[getLocale]  || section.label['en-EN']"
                      prepend-icon="mdi-information"
                      @click:prepend="showDescription(section,section.indexName.replaceAll('.','_'))"
                      :ref="section.indexName.replaceAll('.','_')"
                      :id="section.indexName.replaceAll('.','_')"
                  >
                  </v-combobox>
                </template>

                <template v-if="section.type === 'ALT_LANG_TEXT'">
                  <v-combobox
                      v-model="section.value"
                      :search-input.sync="section.value"
                      :items="distinctItems['metadata.' + section.indexName + '.value']"
                      v-if="(section.formType === 'selection' || section.formType === 'dropdown') && (!section.items || !section.items.length)"
                      :label="section.label[getLocale]  || section.label['en-EN']"
                      prepend-icon="mdi-information"
                      @click:prepend="showDescription(section,section.indexName.replaceAll('.','_'))"
                      :ref="section.indexName.replaceAll('.','_')"
                      :id="section.indexName.replaceAll('.','_')"
                  >
                  </v-combobox>
                  <div style="color: black"
                       v-else-if="section.formType==='description'"
                       :ref="section.indexName.replaceAll('.','_')"
                       :id="section.indexName.replaceAll('.','_')"
                  >
                    <h4>{{ section.label[getLocale] || section.label['en-EN'] }}</h4>
                    <ckeditor :editor="editor"
                              v-model="section.value"
                              :config="editorConfig"></ckeditor>
                  </div>
                  <v-text-field v-model="section.value"
                                v-else
                                :label="section.label[getLocale] || section.label['en-EN']"
                                prepend-icon="mdi-information"
                                @click:prepend="showDescription(section, section.indexName.replaceAll('.','_'));"
                                :ref="section.indexName.replaceAll('.','_')"
                                :id="section.indexName.replaceAll('.','_')">
                  </v-text-field>
                </template>
                <v-combobox
                    v-model="section.value"
                    :items="distinctItems['metadata.' + section.indexName + (section.type==='ALT_LANG_TEXT' ? '.value' : '')]"
                    v-if="section.type === 'TEXT_BAG' && section.formType === 'selection' && (!section.items || !section.items.length)"
                    clearable
                    chips
                    v-on="inputListener"
                    :label="section.label[getLocale]  || section.label['en-EN']"
                    multiple
                    prepend-icon="mdi-information"
                    @click:prepend="showDescription(section,section.indexName.replaceAll('.','_'))"
                    :ref="section.indexName.replaceAll('.','_')"
                    :id="section.indexName.replaceAll('.','_')"
                >
                </v-combobox>
                <v-combobox
                    v-else-if="section.type === 'TEXT_BAG'"
                    v-model="section.value"
                    :items="section.items"
                    chips
                    v-on="inputListener"
                    clearable
                    :label="section.label[getLocale]  || section.label['en-EN']"
                    multiple
                    prepend-icon="mdi-information"
                    @click:prepend="showDescription(section,section.indexName.replaceAll('.','_'))"
                    :ref="section.indexName.replaceAll('.','_')"
                    :id="section.indexName.replaceAll('.','_')"
                >
                </v-combobox>

                <v-combobox
                    v-if="section.type === 'TEXT_SEQ'"
                    v-model="section.value"
                    v-on="inputListener"
                    chips
                    clearable
                    :label="section.label[getLocale]  || section.label['en-EN']"
                    multiple
                    prepend-icon="mdi-information"
                    :items="distinctItems['metadata.' + section.indexName]"
                    @click:prepend="showDescription(section,section.indexName.replaceAll('.','_'))"
                    :ref="section.indexName.replaceAll('.','_')"
                    :id="section.indexName.replaceAll('.','_')"
                >
                </v-combobox>
              </v-col>
            </v-row>
            <v-row>
              <v-expansion-panels flat multiple
                                  v-model="expansions">
                <template v-for="group in imageData.form" v-if="group.enabled">
                  <v-expansion-panel flat :key="group.name" :ref="group.name" v-if="group.name!== 'exif'">
                    <v-expansion-panel-header hide-actions>
                      <h1 class="heading"> {{ group.label[getLocale] || group.label['en-EN'] }}
                        <v-icon>{{ 'mdi-chevron-down' }}</v-icon>
                      </h1>
                    </v-expansion-panel-header>

                    <v-expansion-panel-content>
                      <v-form>
                        <v-row v-for="section in group.fields" :key="section.name" v-if="section.enabled">
                          <v-col md="12" v-if="section.fields.length" class="ml-6">
                            <v-list-group :value="expandAll" :ref="section.indexName.replaceAll('.','_')">
                              <template v-slot:activator>
                                <div class="text-h5 text-darken-1 mb-2">
                                  {{ section.label[getLocale] || section.label['en-EN'] }}
                                </div>
                              </template>

                              <template
                                  v-if="section.fields.length && field.enabled"
                                  v-for="field in section.fields"
                              >
                                <v-col md="12" v-if="field.fields.length && field.enabled" class="ml-6">
                                  <v-list-group :value="expandAll" :ref="field.indexName.replaceAll('.','_')">
                                    <template v-slot:activator>
                                      <div class="text-h5 text-darken-1 mb-2">
                                        {{ field.label[getLocale] || field.label['en-EN'] }}
                                      </div>
                                    </template>

                                    <template
                                        v-if="field.fields.length && subField.enabled"
                                        v-for="subField in field.fields"
                                    >


                                      <v-col md="12" v-if="subField.type === 'TEXT' && !subField.priority">
                                        <v-text-field v-if="subField.formType === 'field'"
                                                      v-model="subField.value"
                                                      :label="subField.label[getLocale] || subField.label['en-EN']"
                                                      prepend-icon="mdi-information"
                                                      @click:prepend="showDescription(subField, subField.indexName.replaceAll('.','_'))"
                                                      :ref="subField.indexName.replaceAll('.','_')"
                                                      :id="subField.indexName.replaceAll('.','_')">
                                        </v-text-field>
                                        <v-select
                                            v-if="subField.formType === 'dropdown' && !subField.priority"
                                            prepend-icon="mdi-information"
                                            @click:prepend="showDescription(subField, subField.indexName.replaceAll('.','_'))"
                                            :items="subField.items"
                                            :label="subField.label[getLocale] || subField.label['en-EN']"
                                            v-model="subField.value"
                                            :ref="subField.indexName.replaceAll('.','_')"
                                            :id="subField.indexName.replaceAll('.','_')"
                                        >
                                        </v-select>
                                        <v-combobox
                                            v-model="subField.value"
                                            :search-input.sync="subField.value"
                                            :items="distinctItems['metadata.' + subField.indexName]"
                                            v-if="subField.formType === 'selection' && (!subField.items || !subField.items.length) && !subField.priority"
                                            :label="subField.label[getLocale]  || subField.label['en-EN']"
                                            prepend-icon="mdi-information"
                                            @click:prepend="showDescription(subField,subField.indexName.replaceAll('.','_'))"
                                            :ref="subField.indexName.replaceAll('.','_')"
                                            :id="subField.indexName.replaceAll('.','_')"
                                        >
                                        </v-combobox>
                                      </v-col>

                                      <v-col md="12" v-if="subField.type === 'ALT_LANG_TEXT' && !subField.priority">
                                        <v-combobox
                                            v-model="subField.value"
                                            :search-input.sync="subField.value"
                                            :items="distinctItems['metadata.' + subField.indexName + '.value']"
                                            v-if="subField.formType === 'selection' && (!subField.items || !subField.items.length)&& !subField.priority"
                                            :label="subField.label[getLocale]  || subField.label['en-EN']"
                                            prepend-icon="mdi-information"
                                            @click:prepend="showDescription(subField,subField.indexName.replaceAll('.','_'))"
                                            :ref="subField.indexName.replaceAll('.','_')"
                                            :id="subField.indexName.replaceAll('.','_')"
                                        >
                                        </v-combobox>
                                        <div style="color: black"
                                             v-else-if="subField.formType==='description'"
                                             :ref="subField.indexName.replaceAll('.','_')"
                                             :id="subField.indexName.replaceAll('.','_')"
                                        >
                                          <h4>{{ subField.label[getLocale] || subField.label['en-EN'] }}</h4>

                                          <ckeditor :editor="editor"
                                                    v-model="subField.value"
                                                    :config="editorConfig"></ckeditor>
                                        </div>
                                        <v-text-field v-model="subField.value"
                                                      v-else
                                                      :label="subField.label[getLocale] || subField.label['en-EN']"
                                                      prepend-icon="mdi-information"
                                                      @click:prepend="showDescription(subField, subField.indexName.replaceAll('.','_'));"
                                                      :ref="subField.indexName.replaceAll('.','_')"
                                                      :id="subField.indexName.replaceAll('.','_')">
                                        </v-text-field>
                                      </v-col>

                                      <!--Components on first layer for TEXT_BAG-->
                                      <v-col md="12" v-if="subField.type === 'TEXT_BAG' && !subField.priority">
                                        <v-combobox
                                            v-model="subField.value"
                                            :items="subField.items"
                                            chips
                                            v-on="inputListener"
                                            clearable
                                            :label="subField.label[getLocale] || subField.label['en-EN']"
                                            multiple
                                            prepend-icon="mdi-information"
                                            @click:prepend="showDescription(subField ,subField.indexName.replaceAll('.','_'))"
                                            :ref="subField.indexName.replaceAll('.','_')"
                                            :id="subField.indexName.replaceAll('.','_')"
                                        >
                                        </v-combobox>
                                      </v-col>

                                      <!--Components on first layer for TEXT_SEQ-->
                                      <v-col md="12" v-if="subField.type === 'TEXT_SEQ' && !subField.priority">
                                        <v-combobox
                                            v-model="subField.value"
                                            chips
                                            v-on="inputListener"
                                            clearable
                                            :label="subField.label[getLocale] || subField.label['en-EN']"
                                            multiple
                                            prepend-icon="mdi-information"
                                            :items="distinctItems['metadata.' + subField.indexName]"
                                            @click:prepend="showDescription(subField ,subField.indexName.replaceAll('.','_'))"
                                            :ref="subField.indexName.replaceAll('.','_')"
                                            :id="subField.indexName.replaceAll('.','_')"
                                        >
                                        </v-combobox>
                                      </v-col>


                                      <!--end of last nesting-->
                                    </template>
                                  </v-list-group>
                                </v-col>

                                <v-col md="12" v-if="field.type === 'TEXT' && !field.priority">
                                  <v-text-field v-if="field.formType === 'field'"
                                                v-model="field.value"
                                                :label="field.label[getLocale] || field.label['en-EN']"
                                                prepend-icon="mdi-information"
                                                @click:prepend="showDescription(field, field.indexName.replaceAll('.','_'));"
                                                :ref="field.indexName.replaceAll('.','_')"
                                                :id="field.indexName.replaceAll('.','_')">
                                  </v-text-field>
                                  <v-select
                                      class="pl-3"
                                      v-else-if="field.formType === 'dropdown' && !field.priority"
                                      prepend-icon="mdi-information"
                                      @click:prepend="showDescription(field, field.indexName.replaceAll('.','_'))"
                                      :items="field.items"
                                      :label="field.label[getLocale] || field.label['en-EN']"
                                      v-model="field.value"
                                      :ref="field.indexName.replaceAll('.','_')"
                                      :id="field.indexName.replaceAll('.','_')"
                                  >
                                  </v-select>
                                  <v-combobox
                                      v-model="field.value"
                                      :search-input.sync="field.value"
                                      :items="distinctItems['metadata.' + field.indexName]"
                                      v-else-if="field.formType === 'selection' && (!field.items || !field.items.length) && !field.priority"
                                      :label="field.label[getLocale]  || field.label['en-EN']"
                                      prepend-icon="mdi-information"
                                      @click:prepend="showDescription(field,field.indexName.replaceAll('.','_'))"
                                      :ref="field.indexName.replaceAll('.','_')"
                                      :id="field.indexName.replaceAll('.','_')"
                                  >
                                  </v-combobox>
                                </v-col>

                                <v-col md="12" v-if="field.type === 'ALT_LANG_TEXT' && !field.priority">
                                  <v-combobox
                                      v-model="field.value"
                                      :search-input.sync="field.value"
                                      :items="distinctItems['metadata.' + field.indexName + '.value']"
                                      v-if="field.formType === 'selection' && (!field.items || !field.items.length) && !field.priority"
                                      :label="field.label[getLocale]  || field.label['en-EN']"
                                      prepend-icon="mdi-information"
                                      @click:prepend="showDescription(field,field.indexName.replaceAll('.','_'))"
                                      :ref="field.indexName.replaceAll('.','_')"
                                      :id="field.indexName.replaceAll('.','_')"
                                  >
                                  </v-combobox>
                                  <div style="color:black"
                                       v-else-if="field.formType==='description'"
                                       :ref="field.indexName.replaceAll('.','_')"
                                       :id="field.indexName.replaceAll('.','_')"
                                  >
                                    <h4>{{ field.label[getLocale] || field.label['en-EN'] }}</h4>

                                    <ckeditor :editor="editor"
                                              v-model="field.value"
                                              :config="editorConfig"></ckeditor>
                                  </div>
                                  <v-text-field v-model="field.value"
                                                v-else
                                                :label="field.label[getLocale] || field.label['en-EN']"
                                                prepend-icon="mdi-information"
                                                @click:prepend="showDescription(field, field.indexName.replaceAll('.','_'));"
                                                :ref="field.indexName.replaceAll('.','_')"
                                                :id="field.indexName.replaceAll('.','_')">
                                  </v-text-field>
                                </v-col>

                                <!--Components on first layer for TEXT_BAG-->
                                <v-col md="12" v-if="field.type === 'TEXT_BAG' && !field.priority">
                                  <v-combobox
                                      v-if="field.items && field.items.length"
                                      :items="field.items"
                                      v-model="field.value"
                                      chips
                                      clearable
                                      v-on="inputListener"
                                      :label="field.label[getLocale] || field.label['en-EN']"
                                      multiple
                                      prepend-icon="mdi-information"
                                      @click:prepend="showDescription(field,field.indexName.replaceAll('.','_'))"
                                      :ref="field.indexName.replaceAll('.','_')"
                                      :id="field.indexName.replaceAll('.','_')"
                                  >
                                  </v-combobox>
                                  <v-combobox
                                      chips
                                      v-on="inputListener"
                                      multiple
                                      v-else
                                      v-model="section.value"
                                      :items="distinctItems['metadata.' + field.indexName]"
                                      clearable
                                      :label="field.label[getLocale]  || field.label['en-EN']"
                                      prepend-icon="mdi-information"
                                      @click:prepend="showDescription(field,field.indexName.replaceAll('.','_'))"
                                      :ref="field.indexName.replaceAll('.','_')"
                                      :id="field.indexName.replaceAll('.','_')"
                                  >
                                  </v-combobox>
                                </v-col>

                                <!--Components on first layer for TEXT_SEQ-->
                                <v-col md="12" v-if="field.type === 'TEXT_SEQ' && !field.priority">
                                  <v-combobox
                                      v-model="field.value"
                                      chips
                                      v-on="inputListener"
                                      clearable
                                      :label="field.label[getLocale] || field.label['en-EN']"
                                      multiple
                                      prepend-icon="mdi-information"
                                      :items="distinctItems['metadata.' + field.indexName]"
                                      @click:prepend="showDescription(field ,field.indexName.replaceAll('.','_'))"
                                      :ref="field.indexName.replaceAll('.','_')"
                                      :id="field.indexName.replaceAll('.','_')"
                                  >
                                  </v-combobox>
                                </v-col>
                              </template>
                            </v-list-group>
                          </v-col>

                          <v-col md="12" v-if="section.type === 'TEXT' && !section.priority">
                            <v-text-field v-if="section.formType === 'field'" v-model="section.value"
                                          :label="section.label[getLocale] || section.label['en-EN']"
                                          prepend-icon="mdi-information"
                                          @click:prepend="showDescription(section, section.indexName.replaceAll('.','_'));"
                                          :ref="section.indexName.replaceAll('.','_')"
                                          :id="section.indexName.replaceAll('.','_')">
                            </v-text-field>

                            <v-select
                                v-else-if="section.formType === 'dropdown' && !section.priority"
                                prepend-icon="mdi-information"
                                @click:prepend="showDescription(section, section.indexName.replaceAll('.','_'))"
                                :items="section.items"
                                :label="section.label[getLocale]  || section.label['en-EN']"
                                v-model="section.value"
                                :ref="section.indexName.replaceAll('.','_')"
                                :id="section.indexName.replaceAll('.','_')"
                            >
                            </v-select>

                            <template
                                v-else-if="section.formType === 'rating' && !section.priority && !isNaN(section.value)">
                              <h1>{{ section.label[getLocale] || section.label['en-EN'] }}</h1>
                              <v-rating color="warning" background-color="grey"
                                        hover
                                        :value="parseInt(section.value)"
                                        @input="(x) => {section.value = x + '.0'}"
                                        :ref="section.indexName.replaceAll('.','_')">

                              </v-rating>
                            </template>

                            <v-combobox
                                v-model="section.value"
                                :search-input.sync="section.value"
                                :items="distinctItems['metadata.' + section.indexName]"
                                v-else-if="(section.formType === 'selection' && (!section.items || !section.items.length)) && !section.priority"
                                :label="section.label[getLocale]  || section.label['en-EN']"
                                prepend-icon="mdi-information"
                                @click:prepend="showDescription(section,section.indexName.replaceAll('.','_'))"
                                :ref="section.indexName.replaceAll('.','_')"
                                :id="section.indexName.replaceAll('.','_')"
                            >
                            </v-combobox>
                          </v-col>

                          <v-col md="12" v-if="section.type === 'ALT_LANG_TEXT' && !section.priority">
                            <v-combobox
                                v-model="section.value"
                                :search-input.sync="section.value"
                                :items="distinctItems['metadata.' + section.indexName +'.value']"
                                v-if="section.formType === 'selection' && (!section.items || !section.items.length) && !section.priority"
                                :label="section.label[getLocale]  || section.label['en-EN']"
                                prepend-icon="mdi-information"
                                @click:prepend="showDescription(section,section.indexName.replaceAll('.','_'))"
                                :ref="section.indexName.replaceAll('.','_')"
                                :id="section.indexName.replaceAll('.','_')"
                            >
                            </v-combobox>
                            <div style="color: black"
                                 v-else-if="section.formType==='description'"
                                 :ref="section.indexName.replaceAll('.','_')"
                                 :id="section.indexName.replaceAll('.','_')"
                            >
                              <h4>{{ section.label[getLocale] || section.label['en-EN'] }}</h4>
                              <ckeditor :editor="editor"
                                        v-model="section.value"
                                        :config="editorConfig"></ckeditor>
                            </div>
                            <v-text-field v-model="section.value"
                                          v-else
                                          :label="section.label[getLocale] || section.label['en-EN']"
                                          prepend-icon="mdi-information"
                                          @click:prepend="showDescription(section, section.indexName.replaceAll('.','_'));"
                                          :ref="section.indexName.replaceAll('.','_')"
                                          :id="section.indexName.replaceAll('.','_')">
                            </v-text-field>
                          </v-col>

                          <!--Components on first layer for TEXT_BAG-->
                          <v-col md="12"
                                 v-if="section.type === 'TEXT_BAG' && (!section.items || !section.items.length) && !section.priority">
                            <v-combobox
                                v-model="section.value"
                                :items="distinctItems['metadata.' + section.indexName]"
                                chips
                                v-on="inputListener"
                                clearable
                                :label="section.label[getLocale]  || section.label['en-EN']"
                                multiple
                                prepend-icon="mdi-information"
                                @click:prepend="showDescription(section,section.indexName.replaceAll('.','_'))"
                                :ref="section.indexName.replaceAll('.','_')"
                                :id="section.indexName.replaceAll('.','_')"
                            >
                            </v-combobox>
                          </v-col>
                          <v-col md="12" v-else-if="section.type === 'TEXT_BAG' && !section.priority">
                            <v-combobox
                                v-model="section.value"
                                :items="section.items"
                                chips
                                v-on="inputListener"
                                clearable
                                :label="section.label[getLocale]  || section.label['en-EN']"
                                multiple
                                prepend-icon="mdi-information"
                                @click:prepend="showDescription(section,section.indexName.replaceAll('.','_'))"
                                :ref="section.indexName.replaceAll('.','_')"
                                :id="section.indexName.replaceAll('.','_')"
                            >
                            </v-combobox>
                          </v-col>

                          <!--Components on first layer for TEXT_SEQ-->
                          <v-col md="12" v-if="section.type === 'TEXT_SEQ' && !section.priority">
                            <!--
                            <v-combobox
                                v-model="section.value"
                                chips
                                v-on="inputListener"
                                clearable
                                :label="section.label[getLocale]  || section.label['en-EN']"
                                multiple
                                prepend-icon="mdi-information"
                                @click:prepend="showDescription(section,section.indexName.replaceAll('.','_'))"
                                :ref="section.indexName.replaceAll('.','_')"
                                :id="section.indexName.replaceAll('.','_')"
                            >
                            </v-combobox>
                            -->

                            <v-combobox
                                v-model="section.value"
                                chips
                                v-on="inputListener"
                                clearable
                                :label="section.label[getLocale]  || section.label['en-EN']"
                                multiple
                                prepend-icon="mdi-information"
                                @click:prepend="showDescription(section,section.indexName.replaceAll('.','_'))"
                                :items="distinctItems['metadata.' + section.indexName]"
                                :ref="section.indexName.replaceAll('.','_')"
                                :id="section.indexName.replaceAll('.','_')"
                            >
                            </v-combobox>
                          </v-col>
                          <v-col class="ml-6" v-if="section.type === 'array'" md="12">
                            <v-divider></v-divider>
                            <v-spacer></v-spacer>
                          </v-col>
                        </v-row>
                      </v-form>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                  <v-expansion-panel v-else-if="group.name === 'exif'" :ref="group.name" :key="group.name">
                    <v-expansion-panel-header hide-actions>
                      <h1 class="heading"> {{ group.label[getLocale] || group.label['en-EN'] }}
                        <v-icon>{{ 'mdi-chevron-down' }}</v-icon>
                      </h1>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-row>
                        <v-col md="6">
                          <v-text-field
                              :label="group.fields[0].label[getLocale] || group.fields[0].label['en-EN']"
                              prepend-icon="mdi-information"
                              @click:prepend="showDescription(group.fields[0], group.fields[0].indexName.replaceAll('.','_'));"
                              :ref="group.fields[0].indexName.replaceAll('.','_')"
                              :id="group.fields[0].indexName.replaceAll('.','_')"
                              v-model="group.fields[0].value">
                          </v-text-field>
                          <v-text-field
                              :label="group.fields[1].label[getLocale] || group.fields[1].label['en-EN']"
                              prepend-icon="mdi-information"
                              @click:prepend="showDescription(group.fields[1], group.fields[1].indexName.replaceAll('.','_'));"
                              :ref="group.fields[1].indexName.replaceAll('.','_')"
                              :id="group.fields[1].indexName.replaceAll('.','_')"
                              v-model="group.fields[1].value">
                          </v-text-field>
                        </v-col>
                        <v-col md="6">
                          <div style="height: 350px;">
                            <!--
                            <l-map @click="getLatLng" :zoom.sync="zoom" :center.sync="isLocation ? latLang : center"
                                   ref="map">
                              <l-tile-layer :url="mapUrl" :attribution="attribution"></l-tile-layer>
                              <l-marker draggable :visible="isLocation" :lat-lng.sync="latLang">
                              </l-marker>
                              <l-control v-if="!isLocation" position="topleft">
                                <v-btn icon rounded @click="centralizeMapToCurrentUserLocation">
                                  <v-icon color="primary">
                                    {{ 'mdi-crosshairs-gps' }}
                                  </v-icon>
                                </v-btn>
                              </l-control>
                            </l-map>
                            -->
                          </div>
                        </v-col>
                      </v-row>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </template>
              </v-expansion-panels>
            </v-row>
          </v-container>
        </v-card>
      </v-col>
    </v-layout>
  </v-container>
</template>

<script>
import {mapActions, mapGetters, mapMutations} from "vuex";
import L from "leaflet";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import {initialCoords} from "../../config";
import DownloadZipMenu from "@/components/DownloadZipMenu";
import AppHeader from "@/components/AppHeader";
import DownloadMenu from "@/components/DownloadMenu";
import PreviewOverlay from "@/components/PreviewOverlay";

export default {
  name: "EditImage",
  props: ['id', 'img'],
  components: {
    DownloadZipMenu,
    AppHeader,
    DownloadMenu,
    PreviewOverlay
  },
  data() {
    return {
      loading: false,
      imageData: {},

      L: L,
      webImages: process.webimagesUrl,
      currentLocation: {},
      expansions: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
      expandAll: true,
      url: '',
      date: '',
      time: '',
      modal: false,
      distinctItems: [],
      keywords: ['first', 'second', 'third'],
      iptcCodes: ['code1', 'code2', 'code3'],
      editor: ClassicEditor,
      editorConfig: {
        placeholder: 'Description',
        label: 'Description',
        toolbar: ['bold', 'italic', '|', 'link', '|',
          'numberedList', 'bulletedList', '|', 'undo', 'redo'],
      },
      formData: new FormData(),
      uploadData: {},
      isShown: false,
      description: '',
      centerVariable: L.latLng(initialCoords.lat, initialCoords.lng),
      zoom: initialCoords.zoom,
      latitude: null,
      longitude: null,
      mapUrl: 'https://map.tourinfra.com/tiles/gs_sommer_web/{z}/{x}/{y}.png',
      attribution: `powered by <a href="https://www.we2p.de/we2p-maps/" target="_blank">we2p&reg; Maps</a> &amp
                             <a href="https://tourinfra.com/" target="_blank">tourinfra &reg;</a> | map data by &copy;
                             <a href="https://www.green-solutions.info/" target="_blank">green-solutions GmbH & Co. KG</a>
                             <a href="https://www.openstreetmap.org/copyright" target="_blank">OSM &amp; Contributors</a>`,
    }
  },
  async created() {
    this.loading = true
    this.setUploadRefs(this.$refs)
    setTimeout(() => {
      this.expandAll = false
    }, 0)

    // get file information
    const file = await this.getFile(this.img);

    // get file metadata
    await this.configParse(this.getConfig.form);
    this.imageData = await JSON.parse(JSON.stringify(this.getConfig));
    this.imageData.id = this.img;
    this.imageData.info = file.info;
    this.imageData.galleryUrl = file.galleryUrl;

    await this.parseFile({data: file.metadata, imageData: this.imageData});

    // load items
    this.loadDistinctItems(this.id).then(res => {
      Object.keys(res).forEach(field => {
        console.log(field, res[field]);
        res[field] = res[field].filter(item => item);
      });

      this.distinctItems = res;
    });

    this.basic = [];
    this.imageData.form.forEach(group => {
      this.fillBasic(group);
    });

    this.basic.sort((b, a) => a.priority - b.priority);
    this.loading = false;
  },
  computed: {
    ...mapGetters({
      getLocale: 'main/getLocale',
      getBasic: 'main/getBasic',
      getCurrentMetadata: 'main/getCurrentMetadata',
      getConfig: 'main/getConfig',
      getDocumentConfig: 'main/getDocumentConfig'
    }),
    inputListener() {
      var vm = this
      return Object.assign({},
          this.$listeners,
          {
            input: (event) => {
              let last = event.pop()
              if (!this.isBlank(last)) {
                event.push(...last.split(/\ *[,;]\ */))
                let res = event.filter((value, index, self) => {
                  return self.indexOf(value) === index
                })
                while (event.length > 0) {
                  event.pop();
                }
                event.push(...res)
              }
            }
          }
      )
    },
    isLocation() {
      return !((!!!this.imageData.form[7].fields[0].value && this.imageData.form[7].fields[0].value !== 0) || (!!!this.imageData.form[7].fields[1].value && this.imageData.form[7].fields[1].value !== 0))
    },
    center: {
      set(value) {
        this.centerVariable = value
      },
      get() {
        return this.centerVariable
      }
    },
    latLang: {
      get() {
        if (this.isLocation) {
          return L.latLng(this.imageData.form[7].fields[0].value, this.imageData.form[7].fields[1].value)
        } else {
          return L.latLng(this.imageData.form[7].fields[0].value || 0, this.imageData.form[7].fields[1].value || 0)
        }
      },
      set({lat, lng}) {
        if (lat !== 0) {
          this.imageData.form[7].fields[0].value = lat
        }
        if (lng !== 0) {
          this.imageData.form[7].fields[1].value = lng
        }
      }
    },
    basic: {
      set(value) {
        this.setBasic(value)
      },
      get() {
        return this.getBasic
      }
    }
  },
  methods: {
    ...mapActions({
      getFile: 'main/getFile',
      getMetadata: 'main/getMetadata',
      loadConfiguration: 'main/loadConfiguration',
      configParse: 'main/configParse',
      parseFile: 'main/parseFileMetadata',

      loadDistinctItems: 'main/loadDistinctItems',
      onMarked: 'main/onMarked',
      upload: 'main/upload',
      download: 'main/downloadFrom'
    }),
    ...mapMutations({
      setUploadRefs: 'main/setUploadRefs',
      setBasic: 'main/setBasic',
      setEditMetadata: 'main/setEditMetadata'
    }),
    split(value) {
      value = value[value.length - 1]?.split(',')
    },
    getLatLng({latlng}) {
      if (!this.isLocation) {
        this.imageData.form[7].fields[0].value = latlng.lat
        this.imageData.form[7].fields[1].value = latlng.lng
      }
    },
    async centralizeMapToCurrentUserLocation() {
      this.zoom = initialCoords.zoom
      if (this.currentLocation.lat) {
        setTimeout(() => {
          this.center = this.currentLocation
        }, 400)
        // Object.assign(this.center, this.currentLocation)
      } else {
        navigator.geolocation.getCurrentPosition(position => {
          this.currentLocation = L.latLng(position.coords.latitude, position.coords.longitude)
          this.center = L.latLng(position.coords.latitude, position.coords.longitude)
        }, {timeout: 7000})
      }
    },
    showDescription(item, id) {
      let description = ''
      if (item.helpText) {
        if (item.helpText[this.getLocale]) {
          description = item.helpText[this.getLocale]
        } else {
          if (item.definition[this.getLocale]) {
            description = item.definition[this.getLocale]
          } else {
            if (item.helpText['en-EN']) {
              description = item.helpText['en-EN']
            } else {
              description = item.definition['en-EN']
            }
          }
        }
      } else {
        description = item.definition[this.getLocale] || item.definition['en-EN']
      }
      setTimeout(() => {
        this.description = description;
        this.isShown = true;
      }, 100)
    },

    async uploadFile(e) {
      await setTimeout(() => {
        return 1
      }, 1000);

      let imageData = {}
      imageData.info = {}
      imageData.info.type = this.imageData.info.type

      /*
      if (this.uploading) {
        imageData.info.marked = this.imageData.info.marked
        const file = this.getFile
        this.formData.append('file', file)
        imageData.info.fileName = file.name
        imageData.info.deleted = false
        imageData.info.archived = false
      } else {
        imageData.id = this.img
        imageData.info = this.imageData.info
        imageData.thumbnail = this.imageData.thumbnail
      }      
       */

      imageData.id = this.img
      imageData.info = this.imageData.info
      imageData.thumbnail = this.imageData.thumbnail
      imageData.info.projectId = this.id

      this.imageData.form.forEach(group => {
        this.uploadParse(group, this.uploadData)
      });

      this.formData.append('mediaFileData', new Blob([JSON.stringify({
        ...imageData,
        metadata: this.uploadData
      })], {type: 'application/json'}))
      await this.upload({formData: this.formData, uploading: false, goBack: true}).catch();
      this.formData = new FormData()
      this.uploadData = {}
    },
    index(obj, is, value) {
      if (typeof is == 'string')
        return this.index(obj, is.split('.'), value);
      else if (is.length === 1 && value !== undefined) {
        if (obj[is[0]] === undefined) {
          obj[is[0]] = {}
        }
        obj[is[0]] = value
        return obj[is[0]];
      } else if (is.length === 0) {
        return obj;
      } else {
        if (obj && obj[is[0]] === undefined) {
          if (value !== undefined)
            obj[is[0]] = {}
          else return
        }
        if (obj)
          return this.index(obj[is[0]], is.slice(1), value);
      }
    },

    async uploadParse(object, uploadData) {
      object.fields.forEach(field => {
        if (!field.fields.length) {
          switch (field.type) {
            case "TEXT":
              this.index(uploadData, field.indexName, field.value || '')
              break;
            case "TEXT_SEQ":
              this.index(uploadData, field.indexName, field.value || [])
              break;
            case "TEXT_BAG":
              if (Array.isArray(field.value)) {
                this.index(uploadData, field.indexName, field.value)
              } else this.index(uploadData, field.indexName, [field.value || ''])
              break;
            case "ALT_LANG_TEXT":
              if (Array.isArray(field.value)) {
                this.index(uploadData, field.indexName, [{lang: 'x-default', value: field.value[0]}])
              } else this.index(uploadData, field.indexName, [{lang: 'x-default', value: field.value}])
              break;
          }
        } else {
          switch (field.type) {
            case "OBJECT_BAG":
              this.uploadParse(field, uploadData)
              this.index(uploadData, field.indexName, [this.index(uploadData, field.indexName)])
              break;
            case "OBJECT":
              this.uploadParse(field, uploadData)
              break;
            case "OBJECT_SEQ":
              this.uploadParse(field, uploadData)
              const seqValue = this.index(uploadData, field.indexName)
              this.index(uploadData, field.indexName, seqValue ? [seqValue] : {})
              break;
          }
        }
      })
    },
    isBlank(str) {
      return (!str || /^\s*$/.test(str));
    },
    fillBasic(parent) {
      parent.fields.forEach(child => {
        if (child.fields && child.fields.length) {
          this.fillBasic(child)
        } else {
          if (Array.isArray(child.value)) {
            child.value = child.value.filter(item => {
              return !this.isBlank(item)
            })
          }
          if (child.priority) {
            this.basic.push(child)
          }
        }
      })
    }
  }
}
</script>

<style scoped>

</style>